.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: rgba(0, 0, 0, 0.85);
}

.ant-select-disabled .ant-select-arrow {
  display: none;
}

.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.85);
}

.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.85);
}

.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.85);
}

.ant-input-affix-wrapper-disabled {
  background-color: #fff;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0;
}

.ant-form-item {
  margin-bottom: '15px';
}

.ant-input-number {
  width: 100%;
}

.ant-input-number-group-wrapper {
  width: 100%;
}

.ant-picker {
  width: 100%;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #777;
}

.ant-table.ant-table-small .ant-table-filter-trigger {
  color: rgba(0, 0, 0, 1);
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.85);
}

.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small tfoot > tr > td,
.ant-table.ant-table-small tfoot > tr > th {
  padding: 4px;
}

/* .ant-menu-submenu-title {
  padding-left: 5px !important;
}

.ant-menu-item {
  padding-left: 5px !important;
}

.ant-layout-sider
  ant-layout-sider-light
  ant-layout-sider-collapsed
  ant-layout-sider-has-trigger {
  max-width: 35px !important;
  min-width: 35px !important;
  width: 35px !important;
} */
